<template>
  <div class='container'>

    <div class="content">
<!--        <div class="view-type">-->
<!--              <div :class="viewType==1?'view-type-item view-type-active':'view-type-item'" @click="viewType=1; currentPage = 1">-->
<!--                <img src="@assets/img/profile/icon-file.png" style="width:20px;"/>文件-->
<!--              </div>-->
<!--              <div :class="viewType==2?'view-type-item view-type-active':'view-type-item'" @click="viewType=2;  currentPage = 1">-->
<!--                <img src="@assets/img/profile/icon-delete.png" style="width:16px;"/>回收站-->
<!--              </div>-->
<!--              <div :class=" viewType == 1? 'line _file' : 'line _recycle' " ></div>-->
<!--            </div>-->
      <!-- 文件操作 -->
      <div class="file-actions" v-if="viewType==1">
        <div class="file-actions-left" v-if="checkLength==0">
          <div class="upload" @mousemove="showSendFile2 = true" @mouseout="showSendFile2 = false" >
            <img src="@assets/img/profile/icon-upload.png"/>
            <span>上传</span>
          </div>

          <div class="create" @click="handleClickCreateFolder">
              <img src="@assets/img/profile/icon-create_folder.png"/>
              <span>新建文件夹</span>
          </div>
<!--          <div class="upload" @click="ldc"  >-->
<!--            <img src="@assets/img/profile/icon-upload.png" />-->
<!--            <span>无敌无敌</span>-->
<!--          </div>-->
          <transition name="el-fade-in">
            <div class="send_file-popver" v-show="showSendFile2"  @mousemove="showSendFile2 = true" @mouseout="showSendFile2 = false" >
              <!--              <div class="cone"></div>-->
              <ul>
                <li >
                  <!--              文件上传-->
                    <img src="@assets/img/index/icon-file.png"/>
                    <span>单个/多个文件</span>
                  <input type="file"   @change="uploadFile($event,'file')" multiple  >
                  <div style="position: absolute;right: 0">
                  </div>
                </li>
                <li  >
                    <img src="@assets/img/index/icon-folder.png"/>
                    <span>整个文件夹</span>
                  <input type="file"   @change="uploadFile($event,'files')"  webkitdirectory  >
                </li>
              </ul>


            </div>
          </transition>

        </div>
        <div class="file-actions-left" v-if="checkLength!=0">
          <div class="actions">
            <div class="action-item" @click="handelsendFile">
              <img src="@assets/img/profile/icon-send.png"/>
              <span>发送</span>
            </div>
            <div class="action-item"   @click="getFileAllSize('','')">
              <img src="@assets/img/profile/icon-download.png"/>
              <span>下载</span>
            </div>
            <div class="action-item"   @click="handelrename(checkLength>1 ?'0':'1')">
              <img src="@assets/img/profile/icon-edit.png"  />
              <span :style="isFile == true && checkLength == 1 ?  '':'color: #BFBFBF;'">重命名</span>
            </div>
            <div class="action-item"  >
              <img src="@assets/img/profile/icon-move.png"/>
              <span :style="isFile == true && checkLength? '':'color: #BFBFBF;'" @click="isFile? popup = true:popup = false;popuptitle='移动到'" >移动到...</span>
            </div>
            <div class="action-item" @click="showDelete=true">
              <img src="@assets/img/profile/icon-delete3.png"/>
              <span>删除</span>
            </div>
            <div class="action-item" @click.stop="showFileActionMore=!showFileActionMore">
              <img src="@assets/img/profile/icon-more2.png"/>
            </div>
            <div class="popover" v-show="showFileActionMore" v-clickoutside="handleClose2">
              <div class="popper__arrow"></div>
              <div class="popover-item" @click="isFile? popup = true:popup = false;popuptitle='复制到'">
                <i class="iconfont icon-fuzhi"></i>
                <span :style="isFile == true && checkLength? '':'color: #BFBFBF;'" >复制到...</span>
              </div>
              <div class="popover-item" @click="handelfileZip('')">
                <i class="iconfont icon-file-zip"></i>
                <span>打包Zip文件</span>
              </div>
            </div>
          </div>
          <p>已选{{checkLength}}项（共{{count}}项）</p>
        </div>
        <div class="file-actions-right">
          <div class="search-type" v-show="false">
            <div class="search-type_val" @click.stop="showSearchType=!showSearchType">{{searchTypeText}}<img src="@assets/img/index/icon-drop.png"/></div>

          </div>
          <div class="search">
            <input type="text" v-model.trim="searchText" placeholder="请输入您想要搜索的文件" @blur="searchTypeisNull"  autocomplete="off"
            @focus="showSearchFileType=true" />
            <div class="btn" @click="searchType = 1;fileList = [];pageIndex = 1;queryState = false;handelsearchAllData(1)">搜索</div>
            <transition name="el-zoom-in-top">
              <div class="file_type-list" v-show="showSearchFileType&&searchText.length==0">
                <div class="file_type-item" v-for="(item,index) in iconList" :key="index"  @mousedown="changedropDownVal(item.type)" >
                  <img :src="item.img"/>
                  <span >{{item.type}}</span>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <!-- 文件 -->
      <div class="pan-contain" v-if="viewType==1" v-loading="loading">
        <div class="back" v-show="searchState" @click="goback">
          <img src="@assets/img/profile/icon-back.png"/>
          <span>返回</span>
        </div>
        <div class="pan-contain-nav">
          <div class="pan-contain-nav-left" v-show="!searchState">
            <div class="titleBox" v-for="(item,index) in yunpanTitle" :key="index"  @click="changeTitle(item,index)"  >
            <img src="@assets/img/index/icon-files.png"/>
            <span>{{item.title}}</span>
              <div style="margin-right: 16px" v-show="yunpanTitle.length - 1 != index"> > </div>
            </div>
<!--            <img class="sort" src="@assets/img/profile/icon-sort.png" style="cursor:pointer;" v-if="showModel==0"/>-->
          </div>
          <div class="pan-contain-nav-left" v-show="searchType == 2  && searchState">
            <img class="tips" :src="getImgType(searchText1)"/>
            <span class="font">{{searchText1}}</span>
            <div class="tipsBox" >
              <span>对搜索结果不满意 ？</span>
              <div class="btn" @click="$router.push('/home')">
                试试全盘搜索
              </div>
            </div>
          </div>
          <div class="pan-contain-nav-left" v-show="searchType == 1 && searchState">
            <span class="font">关键字：{{searchText1}}</span>
            <div class="tipsBox" >
              <span>对搜索结果不满意 ？</span>
              <div class="btn" @click="$router.push('/home')">
                试试全盘搜索
              </div>
            </div>
          </div>
          <div class="pan-contain-nav-right">
            <img :src="showModel == 0 ? list1:list" @click="showModel=0"/>
            <img :src="showModel == 1 ? grid1:grid0" @click="showModel=1;"/>
          </div>
        </div>
        <!-- 文件列表 -->
        <div class="pan-contain-body" v-if="fileTableState">
          <!-- 列表模式 -->
          <div class="pan-table__header" v-if="showModel==0">
            <table>
              <colgroup>
                <col width="5%">
                <col width="30%">
                <col width="30%">
                <col width="20%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th class="pan-table__header-select">
                    <img :src="isCheckAll?selected:unselected" @click="handleClickAll"/>
                  </th>
                  <th style="text-align:left; cursor: pointer" @click="panOrderby('name')" >
                    文件名
                    <img class="sort"
                         v-show="fileSort !=0 && fileSortType == 'name'"
                         :style="{ transform: fileSort == 1 ? 'rotate(0deg)':'rotate(180deg)'} "
                         src="@assets/img/profile/icon-Filesort.png" alt="">
                  </th>
                  <th style="text-align:left; cursor: pointer" @click="panOrderby('create_time')" >
                    创建时间
                    <img class="sort"
                         v-show="fileSort !=0 && fileSortType == 'create_time'"
                         :style="{ transform: fileSort == 1 ? 'rotate(0deg)':'rotate(180deg)'}"
                         src="@assets/img/profile/icon-Filesort.png" alt="">
                  </th>
                  <th style="text-align:left; cursor: pointer" @click="panOrderby('size')" >
                    文件大小
                    <img class="sort"
                         v-show="fileSort !=0 && fileSortType == 'size'"
                         :style="{ transform: fileSort == 1 ? 'rotate(0deg)':'rotate(180deg)'}"
                         src="@assets/img/profile/icon-Filesort.png" alt="">
                  </th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
          <!-- 大图模式 -->
          <div class="pan-grid__header"  v-else>
            <img :src="isCheckAll?selected:unselected" @click="handleClickAll"/>
            <span>全选</span>
          </div>
          <!-- 列表模式 -->
          <div class="pan-table__body" @scroll="scrollEvent($event,1)" v-if="showModel==0"  ref="panListcoordinate" >
            <table>
              <colgroup>
                <col width="5%">
                <col width="30%">
                <col width="30%">
                <col width="20%">
                <col width="15%">
              </colgroup>
              <tbody>
                <!-- 新建文件夹 -->
                <tr v-if="showEditFile">
                  <td></td>
                  <td  class="pan-list__file-name-edit">
                    <img src="@assets/img/type/icon_folder.png"/>
                    <input type="text" v-model.trim="editFile.filename" autocomplete="off"/>
                    <img src="@assets/img/profile/icon-confirm.png" style="cursor:pointer;margin-left:15px;"
                    @click="handleConfirmCreateFolder"/>
                    <img src="@assets/img/profile/icon-close.png" style="cursor:pointer;margin-left:15px;"
                    @click="handleCloseCreateFolder"/>
                  </td>
                  <td class="pan-list__time">{{editFile.date | format('YYYY-MM-DD HH:mm')}}</td>
                  <td class="pan-list__size"></td>
                  <td></td>
                </tr>
                <tr v-for="(item,index) in fileList" :key="index" :class="item.checked?'selected':''"  @contextmenu.prevent="setToolCoordinate($event,item,index)">
                  <td class="pan-table__body-checkbox">
                    <img :src="item.checked?selected:unselected"   @click="changeChecked(item)"
                    :style="item.checked?'visibility: visible;':''"/>
                  </td>
                  <td class="pan-list__file-name" >
                    <div v-if="item.isRename && item.type !=1" class="pan-list__file-name-edit">
                      <img :src="getImgType(item.suffix)"/>
                      <input type="text" v-model.trim="reName" autocomplete="off"/>
                      <img src="@assets/img/profile/icon-confirm.png" style="cursor:pointer;margin-left:15px;"
                           @click="handleConfirmCreateRename(item.id,item)"/>
                      <img src="@assets/img/profile/icon-close.png"   style="cursor:pointer;margin-left:15px;"
                           @click="item.isRename = false"/>
                    </div>
                    <div style="display: flex;align-items: center"  v-else @click="changeFolder(item,index)" >
                      <img :src="getImgType(item.suffix)"/>
                      <router-link to="" >{{item.name?item.name:item.title}}</router-link>
                    </div>

<!--                    <i>12文件</i>-->
                  </td>
                  <td class="pan-list__time">
<!--                    2022-09-12 12:22-->
                     {{ item.create_time * 1000 | format('YYYY-MM-DD HH:mm') }}
                  </td>
                  <td class="pan-list__size">
                    <span>{{item.size?formatSize(item.size ):item.size_total?formatSize(item.size_total / 1000):'-'}}</span>
                  </td>
                  <td class="pan-list__tool" >
                    <img src="@assets/img/profile/icon-download.png" @click="getFileAllSize(item,index)" style="margin-right:20px;"/>
<!--                  @mouseenter="item.showTool=true" @mouseleave="item.showTool=false"-->
                  <button type="button" @click="handelToolPop($event,item,index)">
                      <img src="@assets/img/profile/icon-more3.png"/>
                    </button>
                  </td>
                  <!-- 文件操作 -->
<!--                  -->
                  <div class="tool-pop" ref="pop"  :style="{ top:coordinateT + 'px', left:coordinateL + 'px'}" v-show="item.showTool"
                       @mouseenter="item.showTool=true" @mouseleave="item.showTool=false"
                 >
                    <div class="tool-bar-action" @click="item.type == 1? fileList = []:'';pageIndex = 1;queryState = false;getPanListData(item,item.type,index)">
                      <i class="iconfont icon-dakaiwenjian"></i>
                      <span>打开</span>
                    </div>
                    <div class="tool-bar-action" @click="getFileAllSize(item,index)">
                      <i class="iconfont icon-xiazaidaoru"></i>
                      <span>下载</span>
                    </div>
                    <div class="tool-bar-action"  :style="item.type == 1? 'color: #BFBFBF;':''"   @click="handelisRename(item)" >
                      <i :style="item.type == 1? 'color: #BFBFBF;':''"  class="iconfont icon-bianjishuru"></i>
                      <span :style="item.type == 1? 'color: #BFBFBF;':''"  >重命名</span>
                    </div>
                    <div class="tool-bar-action"   :style="item.type == 1? 'color: #BFBFBF;':''" @click="item.type == 2? popup = true:popup = false;popuptitle='移动到';fileId = item.id ">
                      <i :style="item.type == 1? 'color: #BFBFBF;':''" class="iconfont icon-yidong"></i>
                      <span :style="item.type == 1? 'color: #BFBFBF;':''" >移动到...</span>
                    </div>
                    <div class="tool-bar-action" @click="showDelete = true;delFile.type = item.type;delFile.id = item.id">
                      <i class="iconfont icon-shanchu1"></i>
                      <span>删除</span>
                    </div>
                    <div class="tool-bar-action" :style="item.type == 1? 'color: #BFBFBF;':''" @click="item.type == 2? popup = true:popup = false;popuptitle='复制到';fileId = item.id">
                      <i :style="item.type == 1? 'color: #BFBFBF;':''" class="iconfont icon-fuzhi"></i>
                      <span :style="item.type == 1? 'color: #BFBFBF;':''" >复制到...</span>
                    </div>
                    <div class="tool-bar-action" v-show="searchState" @click="handelJumpFolder(item)">
                      <i  class="newiconfont icon-ai226"></i>
                      <span >跳转到所在目录</span>
                    </div>
                    <div class="tool-bar-action" @click="handelfileZip(item)">
                      <i class="iconfont icon-file-zip"></i>
                      <span>打包Zip文件</span>
                    </div>
                    <div class="tool-bar-action" @click="handelsendFile(item)">
                      <i class="iconfont icon-fasong"></i>
                      <span>发送</span>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- 大图模式 -->
          <div class="pan-grid__body"  @scroll="scrollEvent($event,1)"  v-else>
            <!-- 新建文件夹 -->
            <div class="file-grid__item" v-if="showEditFile">
              <div class="file-grid__item-content" style="border: 1px dashed #3B4E99;border-bottom:0;">
                <img src="@assets/img/profile/icon-grid-folder.png"/>
                <div  class='inputBox'>
                  <!--                  <img src="@assets/img/file/icon-folder.png"/>-->
                  <input type="text" v-model.trim="editFile.filename" autocomplete="off"/>
                  <div class="imgBox">
                    <img class="img1" src="@assets/img/profile/icon-confirm.png" style="cursor:pointer;margin-left:15px;"
                         @click.stop="handleConfirmCreateFolder"/>
                    <img class="img2" src="@assets/img/profile/icon-close.png"   style="cursor:pointer;margin-left:15px;"
                         @click.stop="handleCloseCreateFolder"/>
                  </div>
                </div>
              </div>
              <div class="file-grid__item-bottom">
                <div class="file-grid__item-bottom-edit">
<!--                  <img src="@assets/img/profile/icon-confirm.png" @click="handleConfirmCreateFolder"/>-->
<!--                  <img src="@assets/img/profile/icon-close.png" @click="handleCloseCreateFolder"/>-->
                </div>
              </div>

            </div>
            <div class="file-grid__item" v-for="(item,index) in fileList" :key="index">


              <div class="file-grid__item-content" @contextmenu.prevent="setGridToolCoordinate($event,item,index)"  @click="changeFolder(item,item.type,index)">
                <img :src="getImgType(item.suffix)"/>
                <div v-if="item.isRename && item.type !=1" class='inputBox'>
                  <!--                  <img src="@assets/img/file/icon-folder.png"/>-->
                  <input type="text"  @click.stop="" v-model.trim="reName" autocomplete="off"/>
                  <div class="imgBox">
                    <img class="img1" src="@assets/img/profile/icon-confirm.png" style="cursor:pointer;margin-left:15px;"
                         @click.stop="handleConfirmCreateRename(item.id,item)"/>
                    <img class="img2" src="@assets/img/profile/icon-close.png"   style="cursor:pointer;margin-left:15px;"
                         @click.stop="item.isRename = false"/>
                  </div>
                </div>
                <p   v-else >{{item.name?item.name:item.title}}</p>
              </div>
              <div class="file-grid__item-bottom">
                <div class="file-grid__item-bottom-left">
                  <img :src="item.checked?selected:unselected2" @click="item.checked=!item.checked"/>
                  <span>{{item.size?formatSize(item.size ):item.size_total?formatSize(item.size_total ):'-'}}</span>
                </div>
                <div class="file-grid__item-bottom-right">
<!--                 @mouseenter="item.showTool=true" @mouseleave="item.showTool=false"     -->
                  <button type="button"

                          @click="handelGridToolPop($event,item,index)"
                  >
                    <img src="@assets/img/profile/icon-more.png"/>
                  </button>

                </div>
              </div>
              <!-- 文件操作 -->
              <div class="tool-pop"  :style="{ top: coordinateT + 'px',left: coordinateL + 'px'}" v-show="item.showTool"
                   @mouseenter="item.showTool=true" @mouseleave="item.showTool=false">
                <div class="tool-bar-action" @click="item.type == 1? fileList = []:'';pageIndex = 1;queryState = false; getPanListData(item,item.type,index)">
                  <i class="iconfont icon-dakaiwenjian"></i>
                  <span>打开</span>
                </div>
                <div class="tool-bar-action" @click="getFileAllSize(item,index)">
                  <i class="iconfont icon-xiazaidaoru"></i>
                  <span>下载</span>
                </div>
                <div class="tool-bar-action" :style="item.type == 1? 'color: #BFBFBF;':''"   @click="handelisRename(item)">
                  <i :style="item.type == 1? 'color: #BFBFBF;':''"  class="iconfont icon-bianjishuru"></i>
                  <span :style="item.type == 1? 'color: #BFBFBF;':''"  >重命名</span>
                </div>
                <div class="tool-bar-action" :style="item.type == 1? 'color: #BFBFBF;':''"  @click="item.type == 2?  popup = true:popup = false;popuptitle='移动到';fileId = item.id">
                  <i :style="item.type == 1? 'color: #BFBFBF;':''" class="iconfont icon-yidong"></i>
                  <span :style="item.type == 1? 'color: #BFBFBF;':''"  >移动到...</span>
                </div>
                <div class="tool-bar-action" @click="showDelete = true;delFile.type = item.type;delFile.id = item.id">
                  <i class="iconfont icon-shanchu1"></i>
                  <span>删除</span>
                </div>
                <div class="tool-bar-action" :style="item.type == 1? 'color: #BFBFBF;':''"  @click="item.type == 2? popup = true:popup = false;popuptitle='复制到';fileId = item.id" >
                  <i :style="item.type == 1? 'color: #BFBFBF;':''"  class="iconfont icon-fuzhi"></i>
                  <span :style="item.type == 1? 'color: #BFBFBF;':''"  >复制到...</span>
                </div>
                <div class="tool-bar-action" v-show="searchState" @click="handelJumpFolder(item)">
                  <i  class="newiconfont icon-ai226"></i>
                  <span >跳转到所在目录</span>
                </div>
                <div class="tool-bar-action" @click="handelfileZip(item)">
                  <i class="iconfont icon-file-zip"></i>
                  <span>打包Zip文件</span>
                </div>
                <div class="tool-bar-action" @click="handelsendFile(item)">
                  <i class="iconfont icon-fasong"></i>
                  <span>发送</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pan-contain-empty" v-else>
          <img src="@assets/img/profile/icon-empty.png"/>
          <span>这里是空的，点击“ <span class="upload" @click="showSendFile2 = !showSendFile2">上传</span> ”按钮开始吧</span>
        </div>
      </div>

    </div>
      <!-- 上传文件弹窗 -->
<!--    showUpload-->
      <div class="upload-dialog" v-show="showUpload">
        <div class="upload-dialog__header">
          <div class="upload-dialog__header-title">共{{ upLoadFileList.length }}个文件/已上传{{successFile}}个文件</div>
          <div class="upload-dialog__header-right">
            <img src="@assets/img/file/icon-drop.png" @click="showUploadList=!showUploadList"
                 :style="!showUploadList?'transform:rotate(180deg)':''"/>
            <img src="@assets/img/file/icon-close.png" @click="showUpload = false" style="margin-left:20px;"/>
          </div>
        </div>
        <el-collapse-transition>
          <div class="uploader-list" v-show="showUploadList">
            <div class="file-item" v-for="(item,index) in upLoadFileList" :key="index">
              <div class="file-name">
                <img :src="getImgType(item.suffix)"/>
                <p>{{item.name}}</p>
              </div>
              <div class="file-size">{{formatSize(item.size / 1000 )}}</div>
              <div class="file-operate">
                <span class="speed" v-show="item.state == 'uploading'" >{{item.speed}}</span>
                <span class="progress" v-show="item.state == 'uploading'" >{{item.percentage}}%</span>
<!--                <img src="@assets/img/file/icon-view.png" style="width:12px;"/>-->
<!--                <img src="@assets/img/file/icon-send.png"/> -->
                <img v-show="item.state == 'uploading'" @click="stopUploadFile(item,index)" src="@assets/img/file/icon-close2.png"/>
                <img v-show="item.state == 'waiting'"  src="@assets/img/file/icon-loading.png" class="loading"/>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
    <!--   是否继续打包提示-->
    <div class="downloadPupBox" v-if="showContinueDownLoadTips">
      <div class="downloadPup">
        <img class="del"  @click="showContinueDownLoadTips = false;continueDownLoadTipsState = true" src="@assets/img/index/icon-close2.png" alt="">
        <div class="title">继续打包提示</div>
        <div class="fontBox">
          <div class="font">
            <span>打包这批文件大约需要{{packZipTime}}分钟，建议选择单个文件下载!</span>
          </div>
        </div>
        <div  class="btnBox">
          <div class="btn1" @click="getFileAllSize('','');continueDownLoadTipsState = false;showContinueDownLoadTips = false ">继续打包</div>
          <div class="btn2" @click="showContinueDownLoadTips = false;continueDownLoadTipsState = true">取消</div>
        </div>

      </div>


    </div>
    <!-- 彻底删除弹窗 -->
    <div class="overlay" v-show="showCompleteDelete">
      <div class="wrap">
        <div class="complete_delete-dialog">
          <div class="dialog-header">
            <span>彻底删除(粉碎)</span>
          </div>
          <div class="dialog-body">
            <p>确定要彻底删除(粉碎)已选的{{checkLength2}}项吗？注意：彻底粉碎的文件无法再恢复</p>
          </div>
          <div class="dialog-footer">
            <div class="btn confirm-btn" @click="handelsmashfile">确定</div>
            <div class="btn cancel-btn" @click="showCompleteDelete=false">取消</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 删除弹窗 -->
    <div class="overlay" v-show="showDelete">
      <div class="wrap">
        <div class="delete-dialog">
          <img src="@assets/img/index/icon-close2.png" @click="showDelete = false" class="close"/>
          <div class="dialog-body">
            <p>确定要删除吗？<br/>删除后，还可以在“回收站”找回</p>
            <span>
              <img :src="isCheckedDelete?selected:unselected" @click="isCheckedDelete=!isCheckedDelete"/>永久销毁(无法找回)
            </span>
          </div>
          <div class="dialog-footer">
            <div class="btn confirm-btn" @click="handeldestructionandDel">确定</div>
            <div class="btn cancel-btn" @click="showDelete=false">
              <span>取消</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  移动到-->
    <handelPopup @handelPopupFile="handelPopupFile"  @getPanList="handelPanList" @closepopup="popup = false" :title="popuptitle" v-if="popup"/>
    <!-- 文件预览  -->
    <previewFile
        v-show="showPreviewFile"
        :showPreviewFile="showPreviewFile"
        :fileInfo="fileInfo"
        :fileUrl="fileUrl"
        :fileType="fileType"
        @close="showPreviewFile = false" />
    <!-- 下载预览流量消耗弹窗样式 -->
    <div class="downloadPupBox" v-if="showTooltips">
      <div class="downloadPup">
        <img class="del"   @click="showTooltips = false" src="@assets/img/index/icon-close2.png" alt="">
        <div class="title">流量消耗提示</div>
        <!--      下载-->
        <div class="fontBox" v-if="handelType == 0">
          <span>文件{{formatSize(fileAllSize )}}；</span>
          <span>在线下载需{{ formatSize(fileAllSize ) }}流量</span>
          <span>(您还剩{{formatSize(userInfo.flow_over_total )}})</span>
          <span v-show="!f3">，是否确认下载？</span>
        </div>
        <!--      预览-->
        <div class="fontBox" v-else>
          <span>文件{{formatSize(fileAllSize )}}；</span>
          <span>在线预览需{{ formatSize(fileAllSize ) }}流量</span>
          <span>(您还剩{{formatSize(userInfo.flow_over_total )}})</span>
          <span v-show="!f3">，是否确认预览？</span>
        </div>
        <!--      下载-->
        <div v-if="f1" class="btnBox">
          <div class="btn1" @click="handelfiledownload" >确认下载</div>
          <div class="btn2" @click="showTooltips = false;continueDownLoadTipsState = true" >取消</div>
        </div>
        <!--      预览-->
        <div v-if="f2" class="btnBox">
          <div class="btn1" @click="handelpreviewFile" >确认预览</div>
          <div class="btn2" @click="showTooltips = false" >取消</div>
        </div>
        <!--    流量不足 -->
        <div v-if="f3" class="btnBox" @click="toShop" >
          <div class="btn3">
            当前账户流量余额不足，前往充值
            <img src="@assets/img/index/icons-next.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 文件传输 -->
    <div class="file-transferBox" v-if="transferShow">
      <div class="file-transfer" >
      <div class="transfer-header">
        <h4 @click="reviseUploadTitle" >
          <div  class="inputBox" v-if="uploadTitleState">
            <input v-model="uploadTitleVal"  @blur="handelInput" />
          </div>
          <div class="title" v-else>
            {{ uploadTitle?uploadTitle :'文件传输'}}
          </div>
          <span>共{{formatSize(sendFileAllSize)}}</span>
        </h4>
        <!-- 发文件菜单 -->
        <div class="transfer-header-right">
          <img src="@assets/img/index/icon-close6.png" @click="transferShow = false" />
        </div>
      </div>
      <div class="file-list">
        <div class="file-item" v-for="(item,index) in fileList1" :key="index">
          <div class="filr-item-left" >
            <!--            <img src="@assets/img/index/icon-word.png"/>-->
            <img :src=getImgType(item.suffix)   />
            <!-- <img src="@assets/img/index/icon-img.png"/> -->
            <p >{{item.name}}</p>
          </div>
          <div class="file-item-right">
            <!--            <img src="@assets/img/index/icon-cloud2.png" style="margin-right:10px"/>-->
<!--            <img  @click="delfile(index)" src="@assets/img/index/icon-close2.png"/>-->
          </div>
        </div>
      </div>
      <div class="setting">
          <div class="setting-item" >
            <div class="setting-item-left">
              搜索权限
              <img :src="s1?helpImg1:helpImg0" @mouseenter="s1=true" @mouseleave="s1=false"/>
              <div class="tooltip s1" v-show="s1">
                <div class="cone"></div>
                <p>
                  开启后，该文件可被其他用户搜索或下载，若是私密文件，请谨慎使用。
                </p>
              </div>
            </div>
            <div class="setting-item-right">
              <div :class="searchPermission?'is-checked switch':'switch'" @click="handelsearchPermission">
                <span></span>
              </div>
            </div>
          </div>
          <div class="setting-item">
            <div class="setting-item-left">
              文件定价
              <img :src="s2?helpImg1:helpImg0" @mouseenter="s2=true" @mouseleave="s2=false"/>
              <div class="tooltip s2" v-show="s2">
                <div class="cone"></div>
                <p>
                  其他用户下载该文件需支付您设置的金额，收益可提现。
                </p>
              </div>
            </div>
            <div class="setting-item-right" style="position: relative">
              <span v-if="showPrice1" @click="handelPrice">{{ price == 0?'免费':price + '元'}}</span>
              <div v-else>
                <input
                    maxlength="9"
                    style="width: 70px;text-align: right"
                    placeholder="请输入金额"
                    @blur="showPrice1 = true"
                    v-model="price"
                    type="text"
                    @input="limitInput($event,'value')"
                >
                元
              </div>
              <div class="pricingBox" v-show="showPrice" @mouseout="showPrice2 = false" @mousemove="showPrice2 = true" >
                <div class="send_file-popver"  >
                  <!--                <div class="cone"></div>-->
                  <div class="send_file-popver-title">开通VIP会员</div>
                  <ul>
                    <li>
                      <img src="@assets/img/index/icon-user.png" alt="">
                      解锁文件定价特权
                    </li>
                    <li>
                      <img src="@assets/img/index/icon-user.png" alt="">
                      专属文件传输背景
                    </li>
                    <li>
                      <img src="@assets/img/index/icon-user.png" alt="">
                      尊享空间、流量、折扣
                    </li>
                  </ul>
                  <router-link to="/shop">
                    <div class="send_file-popver-btn">
                      立即开通
                    </div>
                  </router-link>
                </div>

              </div>
            </div>
          </div>
          <div class="setting-item" >
            <div class="setting-item-left">
              提供流量
              <img :src="s3?helpImg1:helpImg0" @mouseenter="s3=true" @mouseleave="s3=false"/>
              <span v-show="uploadBefore.visitor_upload == 0 && isProvideFlow">
                {{formatSize(sendFileAllSize * flow)}}
              </span>
              <div class="tooltip s3" v-show="s3">
                <div class="cone"></div>
                <p>
                  对方无需登录即可下载该文件，消耗您提供的流量。
                </p>
              </div>
            </div>
            <div class="setting-item-right">
              <div class="flow" v-if="isProvideFlow">
                <img src="@assets/img/index/icon-reduce.png" @click.stop="reduce"/>
                <input type="text" v-model="flow"  @input="handelProvideFlow" style="width:40px;text-align: right;text-align: center;margin:0 10px;border: 1px solid #ccc;">
                <img src="@assets/img/index/icon-add.png" @click.stop="upReduce"/>
                <span style="margin-left: 10px;">次</span>
              </div>
              <!--           -->
              <div :class="isProvideFlow?'is-checked switch':'switch'" @click="handelProvideFlow"
                   v-else>
                <span></span>
              </div>
            </div>
          </div>
          <div class="setting-item">
            <div class="setting-item-left">
              允许他人
            </div>
            <div class="setting-item-right" @click="providePermissionVisible=!providePermissionVisible">
              <i>{{providePermission.val}}</i>
              <img src="@assets/img/index/icon-arrow_right.png" class="arrow"
                   :style="providePermissionVisible?'transform:rotate(90deg)':''"/>
              <transition name="el-zoom-in-top">
                <div class="dropdown" v-show="providePermissionVisible">
                  <div class="cone"></div>
                  <ul>
                    <li @click="choiceDropdown(1,'下载、转存及预览')" >下载、转存及预览</li>
                    <li @click="choiceDropdown(2,'仅下载或转存')"  >仅下载或转存</li>
                    <li @click="choiceDropdown(3,'仅预览')" >仅预览</li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
          <div class="setting-item">
            <div class="setting-item-left">
              有效期
            </div>
            <div class="setting-item-right" @click="validPeriodVisible=!validPeriodVisible">
              <i>{{validPeriod.val | validPeriodType}}</i>
              <img src="@assets/img/index/icon-arrow_right.png" class="arrow"
                   :style="validPeriodVisible?'transform:rotate(90deg)':''"/>
              <transition name="el-zoom-in-top">
                <div class="dropdown" v-show="validPeriodVisible">
                  <div class="cone"></div>
                  <ul>
                    <li v-for="(item) in uploadBefore.expiry_day_list" :key="item" @click="choiceTimeDropdown(item,item)"  >{{ item | validPeriodType }}</li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
          <div class="setting-item" style="display:block">
            <div class="setting-item-left">
              补充描述
            </div>
            <div class="setting-item-right" style="margin-top:10px">
              <textarea v-model.trim="supplement" placeholder="请输入描述内容，它将展示给收到的用户。" autocomplete="off"></textarea>
            </div>
          </div>
          <div class="agree">
            <img src="@assets/img/index/icon-agreed2.png" v-if="isTransferAgree" @click="isTransferAgree=false"/>
            <span style="cursor: pointer" v-else class="isAgree" @click="isTransferAgree=true"></span>
            <div class="fontBox">
              <span>我已阅读且同意</span>
              <span class="routerBox" v-for="(item,index) in homeInfo.upload_protocol_list" :key="index">
                      <span @click="toAgree(item)" class="router">《{{item.title}}》</span>
                      <span v-if="homeInfo.upload_protocol_list.length -1 !=  index">和</span>
                    </span>
              <span>并对上传的文件的合法性负全部责任。</span>
            </div>
          </div>
          <div @click="submitUpload" class="btn">
            立即发送
          </div>
        </div>
    </div>
    </div>
    <!-- 传输/分享 -->
    <div class="transfer_shareBox" v-if="transferShareVisible">
      <div class="transfer_share"  >
      <div class="transfer">
        <div v-if="percentage == 100" class="transfer_titleImgBox" >
          <img src="@/assets/img/index/upload_ok.png" alt="">
        </div>
        <CircleLoading v-else  :percentage="percentage"/>
        <p>{{this.speed}}</p>
        <router-link to="">
          <!--          传输预览<img src="@assets/img/index/icon-arrow_right.png"/>-->
        </router-link>
      </div>
      <div class="content">
        <h5>链接或取件码分享</h5>
        <div class="link">
          <div class="link-item">
            <span>链接</span>：
            <p
                v-if="copy1"
                @mouseout="copy1 = false"
                class="copy1"
                v-clipboard:copy="link"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
            >复制链接</p>
            <p v-else @mousemove="copy1 = true" >{{link?link:'上传完成后生成...'}}</p>
          </div>
          <div class="link-item">
            <span>取件码</span>：
            <p
                v-if="copy2"
                @mouseout="copy2 = false"
                class="copy1"
                v-clipboard:copy="link_code"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
            >复制取件码</p>
            <p v-else @mousemove="copy2 = true" >{{link_code?link_code:'上传完成后生成...'}}</p>
          </div>
        </div>
        <h6>
          <i></i>
          <span>其他方式</span>
          <i></i>
        </h6>
        <div class="type">
          <img src="@assets/img/index/icon-qr.png" @click="link?shareQrVisible=true:shareQrVisible=false"/>
          <img src="@assets/img/index/icon-email.png" @click="link?shareEmailVisible=true:shareEmailVisible=false"/>
        </div>
        <!--             link+-->
        <div class="copy"
             @click="handelCopy"
             v-clipboard:success="onCopy"
             v-clipboard:error="onError"
        >一键复制</div>
        <div class="again" v-if="percentage == 100">
          <span @click="againUpload">关闭窗口</span>
        </div>
      </div>
      <!-- 扫码分享 -->
      <div class="share-qr" v-show="shareQrVisible">
        <img src="@assets/img/index/icon-close3.png" class="close" @click="shareQrVisible = false"/>
        <h4>扫码分享</h4>
        <img :src="share_qr" class="qr"/>
        <!--        <a :href="posterUrl" download="share.png"   class="save">保存图片</a>-->
        <div class="save" @click="downloadImg">保存图片</div>
      </div>
      <div class="share-email" v-show="shareEmailVisible">
        <img src="@assets/img/index/icon-close3.png" class="close" @click="shareEmailVisible = false"/>
        <h4>发送至邮箱</h4>
        <div class="send-email" v-for="(item,index) in sendEmail" :key="index">
          <input type="email" v-model.trim="item.email" placeholder="请输入邮箱" autocomplete="off"/>
          <img src="@assets/img/index/icon-clear.png" class="clear" v-show="item.email.length!=0" @click="item.email=''"/>
          <img src="@assets/img/index/icon-email_add.png" class="add" v-if="(index+1)==sendEmail.length" @click="sendEmail.push({email:''})"/>
        </div>
        <div class="send" @click="handelEmail">发送</div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import {
  createShareQr, downloadQrImage,
  getHomeInfo,
  getPanList,
  getSizeTotal,
  inquireTask, sendOutEmail,
  uploadBefore,
  uploadFileByPan
} from "@assets/api/home/home";
import axios from "axios";
import {
  compressPackage, copyFiles,
  createFolder,
  deleteFiles, diskDownload,
  emptyRecycle,
  getRecycleList, jumpFolder, moveFiles, panPreviewFile,
  reductionRecycle,
  rename, searchAll, searchMemberFiles,
  smashFiles, smashRecycleFile, uploadFile
} from "@assets/api/myFiles";
import handelPopup from "@components/handelPopup";
import previewFile from "@components/previewFile";
import SparkMD5 from "spark-md5";
import {formatSize, md5} from "@utils/function";
import {mapState} from "vuex";
import {baseUrl, icon_type} from "../../../../config";
import {getUserInfo} from "@assets/api/user/user";
import Vue from "vue";

const clickoutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.vueClickOutside = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  update() {},
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.vueClickOutside);
    delete el.vueClickOutside;
  },
};
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
export default {
  name: "",
  components: {
    handelPopup,
    previewFile,
  },

  directives:{
    clickoutside
  },

  data() {
    return {
      selected:require('../../../assets/img/profile/icon-selected.png'),
      unselected:require('../../../assets/img/profile/icon-unselected.png'),
      unselected2:require('../../../assets/img/profile/icon-unselected2.png'),
      list1:require('../../../assets/img/profile/icon-list1.png'),
      list:require('../../../assets/img/profile/icon-list.png'),
      grid0:require('../../../assets/img/profile/icon-grid0.png'),
      grid1:require('../../../assets/img/profile/icon-grid1.png'),
      helpImg0:require('@assets/img/index/icon-transfer_help0.png'),  //文件传输帮助图标
      helpImg1:require('@assets/img/index/icon-transfer_help1.png'),  //文件传输帮助图标
      loading:false,
      attrs: {
        accept: [] // 接收的文件类型
      },
      //分页
      count:0,
      pageIndex:1,
      currentPage:1,
      //复制到 和移动到
      popup:false,
      popuptitle:'',
      //上传弹窗
      showSendFile2:false,
      random:'',
      uploadBefore:{},//上传前参数
      yunpanIndex:1,
      viewType:1, //文件 1 / 回收站 2 /我的云盘搜索 3
      uploadLoaded:0,//上传字节
      speed:0,//网速
      showModel:'0',//展示模式  0列表/1大图模式
      uploadSize:0,//上传字节
      fileName:'',//每次上传的文件名
      uploadfileId:'',//文件id
      fileTotalSize:'',//每次上传文件的字节
      showEditFile:false, //新建文件夹显示
      editFile:{
        filename:'',
        date:''
      },
      reName:'',//重命名
      isFile:false,//是否是文件 区分文件和文件夹 重命名
      showFileActionMore:false, //文件操作下拉显示

      showSearchType:false, //搜索类型下拉显示
      searchType:1, //搜索类型 0 普通展示 1关键字搜索展示 2下拉窗搜索展示
      searchTypeText:'请选择', ////搜索类型文字
      searchTypes:[
        {id:0,type:'请选择'},
        {id:1,type:'我的云盘'},
        {id:2,type:'全盘搜索'},
      ],
      searchState:false,//搜索
      searchText:'', //搜索的文件
      searchText1:'',//搜索的文件关键字展示
      showSearchFileType:false, //搜索的文件类型下拉显示

      fileList:[],//云盘列表数据
      fileTableState:true,
      queryState:false,//列表查询状态 如果查询等于空 就不进行查询了
      upLoadFileList:[],//上传文件数组
      upLoadSuccessFileList:[],//上传文件成功数组
      showDelete:false, //删除弹窗显示
      isCheckedDelete:false,//删除文件是否永久销毁
      showDelete1:false,//清空回收站

      showZipList:false,//打包列表弹窗显示
      showZipListType:1, //打包列表 收起 0 /展开 1
      showZip:false,//打包动画
      showUpload:false, //展示上传弹窗
      showUploadList:true, //展示上传列表

      //回收站
      showCompleteDelete:false, //彻底删除弹窗
      recycleList:[],
      //下载
      packZipList:[],

      yunpanTitle:[],//头部数组
      prev_id:'',//文件id
      delFile:{},//悬浮删除
      packZip:{},//悬浮打包成zip
      percentage:0,//上传进度
      fileStatusText: {
        success: 'success',//上传成功
        error: 'error',//上传失败
        uploading: 'uploading',//上传中
        paused: 'paused',//暂停
        waiting: 'waiting'//等待上传
      },
      secretKey:{},//加密后的参数
      sizetotal:0,//上传文件全部字节
      fileId:0,//单选文件id
      // flag:true,//上传开关
      t0: new Date(), // 文件开始上传时间
      cancel:'',//中断请求
      folder_path:'',//云盘上传路径
      iconList:[],
      // 预览文件
      showPreviewFile:false, //文件预览弹窗
      fileInfo:{},//文件
      fileUrl:'',//预览文件链接
      fileType:'',//文件类型
      coordinateL:'',//文件操作坐标
      coordinateR:'',//文件操作坐标
      coordinateT:'',//大图文件操作坐标
      f1:false,//弹窗
      f2:false,//弹窗
      f3:false,//弹窗
      handelType:0,//下载或者预览 0下载 1预览
      showTooltips:false,
      fileAllSize:0,//选中文件总大小
      fileSizeTotalInfo:{},//数据
      previewFileData:'',//预览的文件
      fileSort:2,//文件排序 1正序 2倒叙
      fileSortType:'',//排序类型
      fileSortState:true,//排序状态
      openShareStatus:1,// //全盘分享开关，1=开启发件分享，0=关闭
      deleteState:false,//删除状态
      total:0,//回收站总数
      sensitiveKeywordList:[],//敏感字
      freePreview:1,//预览
      isZip:false,//是否展开下载弹窗
      freeDown:0,//是否免流量
      packZipTime:1,//打包时间
      downLoadList: [],//下载文件数组
      showContinueDownLoadTips:false,//打包窗口提示
      continueDownLoadTipsState:true,//继续打包
      filesList:[],//最后上传文件数组
      transferShow:false,//文件传输弹窗
      uploadTitleState:false,//传输头部状态
      uploadTitleVal:'',
      uploadTitle:'',//传输头部名称
      fileList1:[],//发送文件数组
      s1:false, //搜索权限提示
      s2:false, //定价提示
      s3:false, //提供流量提示
      redepositState:true,//转存状态
      searchPermission:false,//搜索权限
      isProvideFlow:false, //是否自定义提供流量
      providePermissionVisible:false, //允许他人弹窗
      validPeriodVisible:false, //有效期弹窗

      flow:'1',//提供的流量
      providePermission:{id:1,val:'下载、转存及预览'}, //允许他人
      validPeriod:{},//有效期
      supplement:'', //补充描述
      isTransferAgree:false, //文件传输 协议
      price:0,//文件定价
      showPrice:false,//会员定价弹窗
      showPrice1:true,//会员定价 输入框 和免费切换
      showPrice2:true,//会员定价弹窗 自动消失
      homeInfo:{},
      sendFileAllSize:0,//发送文件总大小

      //传输分享
      transferShareVisible:false,
      shareQrVisible:false, //扫码分享弹窗
      shareEmailVisible:false, //邮箱分享弹窗
      sendEmail:[{email:''}], //分享至的邮箱
      copy1:false,//复制链接按钮
      copy2:false,//复制取件码按钮
      posterUrl:'',//海报地址
      link:'',
      link_code:'',
      share_url:'',//分享完整链接包含id
      share_id:0,//	分享链接的主键id
      share_qr:'',
    };
  },
  watch:{
    //开始下载后关闭弹窗
    closePopup(newVal){
      if(!newVal){
        this.showTooltips = false
        this.continueDownLoadTipsState = true
      }
    },
    //完成打包Zip
    isPackZip(newVal){
      if(this.$store.state.prevId == this.prev_id){
        this.pageIndex = 1
        this.fileSort = 0
        this.fileList = []
        this.queryState = false
        this.getPanListData({id:this.$store.state.prevId,state:true},1,'')

      }
    },
    fileList:{
      handler(newVal){
        if(newVal.length == 0){
          this.fileTableState = false
        }else {
          this.fileTableState = true
        }
      },
      deep:true,
      immediate:true
    },
    viewType(val){
      console.log('val',val)
      if(val == 1){
        this.pageIndex = 1
        this.fileSort = 0
        this.fileList = []
        this.queryState = false
        this.getPanListData('',1,'')
      }
      if(val == 2){
        this.pageIndex = 1
        this.fileSort = 0
        this.recycleList = []
        this.deleteState = false
        this.getRecycleListData()

      }
    },
    checkLength(newVal){
      //重命名
      for(let item in this.fileList){
        if(this.fileList[item].checked && this.fileList[item].type == 1){
          this.isFile = false
          break
        }else{
          this.isFile = true
        }
      }
    },
    //上传文件
    uploadRandom(newVal){
      let fileInfo = this.uploadResponse

      let data  = {
        prev_id:fileInfo.id,
        files_list:[]
      }
      for(let item in fileInfo.files){
        data.files_list.push({
          name:fileInfo.type == 'file'?fileInfo.files[item].file.name:fileInfo.files[item].file.webkitRelativePath,
          size:fileInfo.files[item].file.size
        })
      }
      uploadFile(data).then(res=>{
        if(res.code == 1){
          // console.log('上传成功')
        }else{
          this.$Message.error(`${res.msg}`)
        }

      })
    },
    //全部文件上传完
    isUploadFileAll(){
      console.log('全部文件上传完')
      // this.isUploadFileAll = false
      setTimeout(()=>{
        this.pageIndex = 1
        this.currentPage = 1
        this.queryState = false
        this.fileList = []
        this.getPanListData({id:this.prev_id,state:true},1,'')
        this.getUser()
        this.getuploadBeforeData()
      },300)


    },
    //切换列表预览模式
    showModel(){
      this.fileList.forEach(item=>{
        item.showTool = false
      })
    },
    //点击我的云盘tabbar 回到全部文件
    myFilesRandom(){
      if(this.yunpanTitle.length == 1) return false

      this.fileList = []
      this.queryState = false
      this.pageIndex = 1
      this.yunpanTitle = []
      this.yunpanTitle.push({
        title:'全部文件',
        id:''
      })
      this.getPanListData('',1,'')
    },
    //中断上传后重新获取云盘空间
    stopUploadFile(){
      this.$store.state.surplusMaxSpace = 0
      this.getuploadBeforeData()
    },
    //发送弹窗定价会员弹窗
    showPrice2(newVal){
      if(newVal){
        window.clearTimeout(this.timer)
        this.showPrice = true
      }else{
        this.showPrice = false
      }
    },

  },
  computed: {
    ...mapState({
      myFilesRandom:state => state.myFilesRandom,
      stopUploadFile:state => state.stopUploadFile,
      surplusMaxSpace:state => state.surplusMaxSpace,
      isUploadFileAll:state => state.isUploadFileAll,
      response:state => state.response,
      flag:state => state.flag,
      uploadResponse:state => state.uploadResponse,
      uploadRandom:state => state.uploadRandom,
      userInfo:state => state.userInfo,
      closePopup:state => state.closePopup,
      isPackZip:state => state.isPackZip,
      token:state => state.token
    }),
    ...mapState(['response', 'flag','uploadResponse','uploadRandom','userInfo','closePopup','isPackZip']),
    filesize(){
      let size = 0;
      this.upLoadFileList.forEach(item=>{
        item.files.filter(data=>{
          return  size += data.file.size
        })
      })
      return size
    },
    //上传文件数量
    upLoadFileNum(){
      let num = 0
      this.upLoadFileList.filter(item=>{
        return num += item.files.length
      })
      return  num
    },
    //选中的文件数量
    checkLength(){
      return this.fileList.filter(item=>{
        return item.checked
      }).length
    },
    //判断全选
    isCheckAll(){
      if(!this.fileList.length){
          return false
      }
      return this.fileList.length===this.checkLength
    },
    //回收站 选中的文件数量
    checkLength2(){
      return this.recycleList.filter(item=>{
          return item.checked
      }).length
    },
    //回收站 判断全选
    isCheckAll2(){
      if(!this.recycleList.length){
          return false
      }
      return this.recycleList.length===this.checkLength2
    },
    //上传成功的文件
    successFile(){
      return this.upLoadFileList.filter(item=>{
        return item.state == 'success'
      }).length
    },
  },
  filters:{
    //过滤有效期
    validPeriodType(val){
      if(val == 0) return '永久有效'
      else{
        return  val + '天'
      }
    },
  },
  created() {
    this.getuploadBeforeData()
    this.getPanListData('',1,0)
    // this.getRecycleListData()
    let fileList = this.$store.state.uploadFile.fileList
    if(fileList){
      this.upLoadFileList = fileList
    }
    //搜索下拉框数据
    for(let item in icon_type){
      this.iconList.push({
        img:icon_type[item],
        type:item
      })
    }
    this.getHomeData()
  },

  methods: {
    //用户信息
    getUser(){
      getUserInfo().then(res=>{
        if(res.code == 1){
          let userInfo = res.data
          // let time = userInfo.vip_expiry_time.split(' ')
          // userInfo.vip_expiry_time = time[0]
          this.$store.commit('getUserInfo',userInfo)
        }
      })
    },

    //获取首页参数
    getHomeData(){
      getHomeInfo().then(res=>{
        this.homeInfo = res.data
      })
    },

    //上传文件
    async uploadFile(file,type){
      // let homeInfo = JSON.parse(localStorage.getItem('home'))
      if(this.homeInfo.open_share_status == 0){
        file.target.value = ''
        this.$Message.error('分享者已关闭上传功能')
        return  false
      }
      // this.getuploadBeforeData()
      let size = 0
      let state = false
      let files = file.target.files
      let fileArr = []
      let folder = []
      let obj = {}
      let isUpload = true
      let maxLength = this.upLoadFileList.length

      for(let item in files){
        if(files[item].name && files[item].size){
          fileArr.push(files[item])
        }
      }
      fileArr.forEach((item,index)=>{
        if(this.$filterKeyword(item.name,this.sensitiveKeywordList)){
          // this.fileList1 = []
          // this.transferShow = false
          this.card = true
          isUpload = false
          return  this.$Message.error('该文件涉嫌违反国家相关法律法规，禁止上传和分享！')
        }
        obj = {
          name:item.webkitRelativePath?item.webkitRelativePath.substring(0,item.webkitRelativePath.indexOf('/')):item.name,
          files:[],
          size:item.size,
          md5:'',
          suffix:item.webkitRelativePath?'folder':item.name.substring(item.name.lastIndexOf('.') + 1),
          state:'waiting',
          uuid:this.$uuid(2,8),
          id:this.prev_id,
          type:item.webkitRelativePath?'files':'file',
          percentage:0,
          folderPath:this.folder_path
        }
        size += item.size
        obj.files.push({
          file:item,
          md5:'',
          name:item.name,
          size:item.size,
          state:'waiting',
          uuid:this.$uuid(2,8)
        })
        if(type == 'files'){
          folder.push({
            file:item,
            md5:'',
            name:item.name,
            size:item.size,
            state:'waiting',
            uuid:this.$uuid(2,8),
            id:this.prev_id,
            type:'files',
            folderPath:this.folder_path
          })
          // size +=item.size
          obj.files = folder
          obj.size =  size
        }else{
          this.upLoadFileList.push(obj)
        }
      })
      if(type == 'files'){
        this.upLoadFileList.push(obj)
      }
      //上传完文件清空上传
      file.target.value = ''
      //是否可以上传
      if(!isUpload) return  false

      //上传弹窗
      // this.showUpload = true
      // console.log('本次上传文件大小',size / 1024 )
      // console.log('剩余空间大小',this.surplusMaxSpace)
      if((size / 1024) > this.surplusMaxSpace){
        setTimeout(()=>{
          this.$router.push({
            path:'/shop',
            name:'Shop',
            params:{
              index:2
            }
          })
        },2000)
        this.upLoadFileList.splice(maxLength,this.upLoadFileList.length)
        return this.$Message.error('当前账户云盘空间不足，请扩容后使用')
      }
      this.$store.state.surplusMaxSpace = this.$store.state.surplusMaxSpace - (size / 1024 )
      this.$store.commit('getUploadFile',{
        fileList:this.upLoadFileList,
        uploadBefore:this.uploadBefore,
        folder_path:this.folder_path,
        uploadState:true,
      })
      this.$store.state.isUploadFileAll = false
      this.$store.commit('submit',this.flag)
    },
    //上传前
    getuploadBeforeData(){
      uploadBefore().then(res=>{
        this.uploadBefore = res.data
        if(this.surplusMaxSpace == 0){
          this.$store.state.surplusMaxSpace = res.data.upload_size_max
        }
        this.uploadBefore.save_key =   res.data.upload_path + '/' + res.data.uuid_path +'/' + res.data.time_path +'/'
        let id  = this.token? this.uploadBefore.expiry_day_list[1] : this.uploadBefore.expiry_day_list[0]
        let val  = this.token? this.uploadBefore.expiry_day_list[1] : this.uploadBefore.expiry_day_list[0]
        this.$set(this.validPeriod,'id',id)
        this.$set(this.validPeriod,'val',val)
      })
    },
    //获取云盘数据
    getPanListData(data,type,index,orderBy){
      this.prev_id = data?data.id:''
      //如果查询等于空就不进行查询
      if(this.queryState) return false
      let params = {
        prev_id:this.prev_id,
        operate:1,
        page:this.pageIndex,
        limit:50,
        sort:this.fileSort == 0 ? 2: this.fileSort,
        order_by:orderBy?orderBy:'create_time',
      }
      //只有文件夹 才会进行查询操作
      if(type == 1){
        this.loading = true
        getPanList(params).then(res=>{
          // this.fileList = []
          this.loading = false
          let arr = res.data.list
          this.count = res.data.count
          //上传开关
          this.openShareStatus = res.data.open_share_status
          //敏感关键字
          this.sensitiveKeywordList = res.data.violation_words_list
          this.folder_path = res.data.folder_path
          if(data.state == false || data.state == null || data.state == undefined){
            if(res.data.prev_id == null){
              this.yunpanTitle = []
              this.pageIndex = 1
              this.yunpanTitle.push({
                title:'全部文件',
                id:''
              })
            }else{
              let state = false
              for(let item in this.yunpanTitle){
                if(this.yunpanTitle[item].id == data.id){
                  state = true
                  this.yunpanTitle.splice(index+1,this.yunpanTitle.length - item)
                  break
                }
              }
              if(!state){
                this.yunpanTitle.push({
                  id:data.id,
                  title:data.name
                })
              }
            }
          }
          for(let item in arr){
            this.$set(arr[item],'checked',false)
            this.$set(arr[item],'showTool',false)
            this.$set(arr[item],'isRename',false)
            if(arr[item].type == 1){
              this.$set(arr[item],'suffix','folder')
            }
            this.fileList.push(arr[item])
          }
          if(arr.length == 0 ) return this.queryState = true
          // this.total = res.data.count


        })
      }else{
        //打开文件
        this.openFile(data)
      }
    },
    //打开文件
    openFile(item){
      console.log('item',item)
      if(
          item.suffix !== 'png' &&
          item.suffix !== 'jpg' &&
          item.suffix !== 'mp3' &&
          item.suffix !== 'mp4' &&
          item.suffix !== 'pdf' &&
          item.suffix !== 'jpeg' &&
          item.suffix !== 'docx' &&
          item.suffix !== 'doc'
      ){

        return  this.$Message.error('此文件暂不支持在线预览，请下载后查看')
      }
      let data = {
        files_list : [],
      }
      this.freePreview = 0
      data.files_list.push({
        id:item.id,
        type:item.type
      })
      this.handelType = 1
      this.previewFileData = item
      getSizeTotal(data).then(res=>{
        //选中文件的总大小
        this.fileAllSize = res.data.size_total
        //打包下载总大小
        let unpackSize = res.data.unpack_size
        //免流量下载最大限制
        let visitorDownloadMax = res.data.visitor_download_max

        let previewMin = res.data.preview_min
        //文件小于免流量预览最大限制 直接下载 不需要弹窗
        if(this.fileAllSize <= previewMin){
          this.handelpreviewFile()
          this.freePreview = 1
        }
        //文件大于于免流量预览最大限制 需要弹窗
        if(this.fileAllSize >=previewMin) {
          this.showTooltips = true
          this.f1 = false
          this.f2 = true
          this.f3 = false
        }
        //流量不够
        if(this.userInfo.flow_over_total < this.fileAllSize ){
          this.showTooltips = true
          this.f1 = false
          this.f2 = false
          this.f3 = true
        }
      })
    },
    //预览文件
    handelpreviewFile(){
      let params = {
        file_id:this.previewFileData.id
      }
      console.log('data',this.previewFileData)
      panPreviewFile(params).then(  res=>{
        if(res.code == 1){
          this.fileInfo = this.previewFileData
          this.fileUrl =   res.data.file_url
          this.fileType = res.data.file_suffix
          this.showPreviewFile = true
          this.showTooltips = false
          if(this.freePreview == 0){
            this.$notify({
              title: `消费${formatSize(this.fileAllSize)}流量成功`,
              message: '现在您可以在线预览该文件了',
              type: 'success',
              duration:30000
            });
          }else{
            this.$notify({
              title: `本次预览免流量`,
              message: '现在您可以在线预览该文件了',
              type: 'success',
              duration:30000
            });
          }

          this.getUser()
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //头部查询
    changeTitle(item,index){
      this.fileList = []
      this.queryState = false
      console.log('this.fileList',this.fileList)
      this.pageIndex = 1
      this.getPanListData(item,1,index)
    },
    //点击文件夹查询
    changeFolder(item,index){
      console.log('item',item)
      if(item.type == 1){
        this.fileList = []
        this.pageIndex = 1
        this.queryState = false
        this.getPanListData(item,item.type,index)
      }else{

        if(
            item.suffix == 'png' ||
            item.suffix == 'jpeg' ||
            item.suffix == 'jpg' ||
            item.suffix == 'mp3' ||
            item.suffix == 'mp4' ||
            item.suffix == 'pdf'  ||
            item.suffix == 'docx' ||
            item.suffix == 'doc'
        ){

          this.openFile(item)
        }else{
          this.$Message.error('此文件暂不支持在线预览，请下载后查看')
        }

      }
    },
    //获取回收站数据
    getRecycleListData(){
      let params ={
        page:this.pageIndex,
        limit:50
      }
      getRecycleList(params).then(res=>{
        let arr = []
        arr = res.data.list
        this.total = res.data.count
        for(let item in arr){
          this.$set(arr[item],'checked',false)
          if(arr[item].type == 1){
            this.$set(arr[item],'suffix','folder')
          }
          this.recycleList.push(arr[item])
        }
      })
    },
    handleClose(){
      this.showSearchType=false
    },
    //多个文件操作下拉隐藏
    handleClose2(){
      this.showFileActionMore=false
    },
    //我的云盘下拉框搜索
    changedropDownVal(data){
      console.log('data',data)
      this.showSearchFileType = false
      this.searchType = 2
      // this.searchTypeText = '我的云盘'
      this.searchText = data
      this.pageIndex = 1
      this.fileList = []
      this.queryState = false
      this.fileSort = 0
      console.log('searchType',this.searchType)
      this.handelsearchAllData(2)
    },
    //我的云盘搜索
    handelsearchAllData(type,orderBy){
      if(this.searchText == ''){
        return this.$Message.error('关键字不能为空')
      }
      console.log('state',this.queryState)
      if(this.queryState) return  false
      let params = {
        search_type:type,
        keywords:this.searchText,
        page:this.pageIndex,
        limit:50,
        sort:this.fileSort == 0 ? 1: this.fileSort,
        order_by:orderBy?orderBy:'',
      }
      this.searchText1 = this.searchText
      searchMemberFiles(params).then(res=>{
        this.searchState = true
        if(res.code == 1){
          this.loading = false
          // this.fileList = res.data.list
          let arr = res.data.list
          if(arr.length == 0){
            this.queryState = true
          }
          if(this.pageIndex == 1 && arr.length == 0)return  this.$Message.error('没有搜索到您检索的文件')

          for (let item in arr){
            this.$set(arr[item],'checked',false)
            this.$set(arr[item],'showTool',false)
            this.$set(arr[item],'isRename',false)
            this.$set(arr[item],'suffix',arr[item].suffix)
            if(arr[item].type == '1'){
              this.$set(arr[item],'suffix','folder')
            }
            this.fileList.push(arr[item])
          }
          this.total = res.data.count
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //当输入框为空时
    searchTypeisNull(){
      if(this.searchText == ''){
        this.searchType = 0
        this.searchState = false
        this.fileList = []
        this.pageIndex = 1
        this.queryState = false
        this.showSearchFileType = false
        this.getPanListData('',1,'')
      }
    },
    //点击全选 文件
    handleClickAll(){
      if(this.isCheckAll){
        this.fileList.forEach(item=>item.checked=false)
      }else{
        this.fileList.forEach(item=>item.checked=true)
      }
    },
    //点击全选 回收站
    handleClickAll2(){
      if(this.isCheckAll2){
        this.recycleList.forEach(item=>item.checked=false)
      }else{
        this.recycleList.forEach(item=>item.checked=true)
      }
    },
    //点击新建文件夹
    handleClickCreateFolder(){
      this.editFile.date=new Date();
      this.showEditFile=true;
      this.fileTableState = true
    },
    //确认新建文件夹
    handleConfirmCreateFolder(){
      if(this.editFile.filename==''){
        return this.$Message.error({
          content:'文件(夹)名称不能为空，请输入文件名称',
          duration:3
        })
      }
      let params = {
        name:this.editFile.filename,
        prev_id:this.prev_id,
      }
      createFolder(params).then(res=>{
        console.log('res',res)
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.fileList = []
          this.pageIndex = 1
          this.queryState = false
          this.getPanListData({id:this.prev_id,state:true},1,'')
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
      this.handleCloseCreateFolder();
    },
    //取消新建文件夹
    handleCloseCreateFolder(){
      this.showEditFile=false;
      this.editFile.filename='';
      if(this.fileList.length == 0){
        this.fileTableState = false
      }else{
        this.fileTableState = true
      }
    },
    //navBar重命名
    handelrename(index){
      if(index == 1){
        //重置input
        let strName = this.fileList.find(item=> item.checked == true).name
        this.reName = strName.lastIndexOf('.')? strName.substr(0,strName.lastIndexOf('.')) : strName
        let obj = this.fileList.find(item=> item.checked == true)
        this.$set(obj,'isRename',true)
      }
    },
    handelisRename(item){
      item.isRename = !item.isRename ;
      let strName = item.name.lastIndexOf('.')? item.name.substr(0,item.name.lastIndexOf('.')) : item.name
      this.reName = strName
    },
    //确认重命名
    handleConfirmCreateRename(id,item){
      let params = {
        id:id,
        name:this.reName
      }
      rename(params).then(res=>{
        if(res.code == 1){
          item.name = res.data.new_name
          item.isRename = false
          console.log('item',item)
          // this.getPanListData('',1,'')

          this.$Message.success(`${res.msg}`)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //删除或者销毁
    handeldestructionandDel(){

      let files_list = []
      // if(this.delFile.id && this.delFile.type){
      //   files_list.push(this.delFile)
      //   // console.log('this.delFile',this.delFile)
      //
      // }else{
      //   for(let item in this.fileList){
      //     if(this.fileList[item].checked == true){
      //       files_list.push({
      //         id:this.fileList[item].id,
      //         type:this.fileList[item].type
      //       })
      //     }
      //   }
      // }

      for(let item in this.fileList){
        if(this.fileList[item].checked == true){
          files_list.push({
            id:this.fileList[item].id,
            type:this.fileList[item].type
          })
        }
      }
      let data = {
        files_list:files_list,
        smash_status: this.isCheckedDelete?1:0
      }
      console.log('data',data.files_list)
        deleteFiles(data).then(res=>{
          if(res.code == 1){
            this.$Message.success(`${res.msg}`)

            let promise = new Promise((resolve, reject) => {
              resolve(true)
            })

            promise.then(res=>{
              this.fileList = []
              this.pageIndex = 1
              this.queryState = false
              this.showDelete = false
              this.isCheckedDelete = false
              this.delFile = {}
              console.log('1')
            })
             promise.then(res=>{
               console.log('2')
               this.getPanListData({id:this.prev_id,state:true},1,'')
              this.getUser()
            })

            // setTimeout(()=>{
            //
            // },800)
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
    },
    //悬浮文件删除
    deleteFile(item){
      let files_list = []

      files_list.push({
        id:item.id,
        type:item.type
      })
      let data = {
        files_list:files_list
      }
      if(this.isCheckedDelete){
        smashFiles(data).then(res=>{
          if(res.code == 1){
            this.$Message.success(`${res.msg}`)
            this.getPanListData('',1,'')
            this.showDelete = false
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
      }else{
        deleteFiles(data).then(res=>{
          if(res.code == 1){
            this.$Message.success(`${res.msg}`)
            this.getPanListData('',1,'')
            this.showDelete = false
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
      }
    },
    //还原文件
    handelreductionfile(){
      let files_list = []
      for(let item in this.recycleList){
        if(this.recycleList[item].checked == true){
          files_list.push({
            id:this.recycleList[item].id
          })
        }
      }
      let data = {
        files_list: files_list
      }
      let set = new Set(data.files_list)
      console.log('set',set)
      reductionRecycle(data).then( async res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.recycleList = []
          this.pageIndex = 1
          await this.getRecycleListData()
          await this.getUser()
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //粉碎文件
    handelsmashfile(){
      if(this.deleteState) return  false
      let files_list = []
      for(let item in this.recycleList){
        if(this.recycleList[item].checked == true){
          files_list.push({
            id:this.recycleList[item].id,
            type:this.recycleList[item].type
          })
        }
      }
      let data = {
        files_list:files_list
      }
      this.deleteState = true
      smashRecycleFile(data).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.recycleList = []
          this.pageIndex = 1
          this.getRecycleListData()
          this.showCompleteDelete = false
          this.deleteState = false
        }else{
          this.$Message.error(`${res.msg}`)
        }
      }).catch(error=>{
        this.deleteState = false
      })
    },
    //清空回收站
    handelemptyrecycle(){
      emptyRecycle().then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.recycleList = []
          this.pageIndex = 1
          this.getRecycleListData()
          this.showDelete1 = false
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //打包为zip
    handelfileZip(item){
      let params = {
        files_list:[],
        prev_id:'',
      }
      // if(item != ''){
      //   params.files_list.push({
      //     id:item.id,
      //     type:item.type?item.type:2
      //   })
      //   params.prev_id = this.prev_id
      // }else{
      //   for(let item in this.fileList){
      //     if(this.fileList[item].checked == true){
      //       params.files_list.push({
      //         id:this.fileList[item].id,
      //         type:this.fileList[item].type
      //       })
      //       params.prev_id = this.prev_id
      //     }
      //   }
      // }

      for(let item in this.fileList){
        if(this.fileList[item].checked == true){
          params.files_list.push({
            id:this.fileList[item].id,
            type:this.fileList[item].type
          })
          params.prev_id = this.prev_id
        }
      }

      this.$store.commit('handelPackZip',params)
    },
    //下载
    async handelfiledownload(){
      let params = {
        files_list:this.downLoadList,
      }

      this.$store.state.closePopup = true
      this.$store.commit('handelDownLoadData',{
        totalSize:this.fileAllSize,//总大小
        downLoadList:params.files_list,//数据
        flowOverTotal:this.userInfo.flow_over_total,//用户流量
        visitorDownloadMax:this.fileSizeTotalInfo.visitor_download_max,//免流量下载最大限制
        freeDown:this.freeDown,
        isZip:this.isZip,
        time:this.packZipTime,
        type:'files',
        flowType:true
      })
    },
    isWeChatPC() {
      var ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('micromessenger') != -1 && (ua.indexOf('windowswechat') != -1 || ua.indexOf('macwechat') != -1);
    },
    //获取选中文件的总大小 下载
    getFileAllSize(item,index){
      if(this.isWeChatPC()){
        this.$Message.error('当前浏览器不支持大文件分发，请使用其它浏览器下载')
        return false
      }
      let data = {
        files_list : [],
      }
      console.log('点击了吗')
      if(item.checked == false){
        item.checked = true
        for(let key in this.fileList){
          if(key == index){
            this.fileList[key].checked = true
          }else{
            this.fileList[key].checked = false
          }
        }
      }
      for(let key in this.fileList){
        if(this.fileList[key].checked == true){
          data.files_list.push({
            id:this.fileList[key].id,
            type:this.fileList[key].type
          })
        }
      }

      this.handelType = 0
      getSizeTotal(data).then(res=>{
        let resData = res.data
        let list = data.files_list
        this.downLoadList = data.files_list
        this.fileAllSize = res.data.size_total
        this.fileSizeTotalInfo = res.data
        this.freeDown = res.data.free_down
        //是否需要打包下载
        //文件数量超过1 需要打包
        if(list.length > 1){
          this.isZip = true
        }else{
          //判断单个文件的时候 是什么类型 2文件 1文件夹
          if(list[0].type == 2){
            this.isZip = false
          }else{
            this.isZip = true
          }
        }
        let time = Math.ceil(this.fileAllSize / 1670)
        if(time > 60 && this.isZip &&  this.continueDownLoadTipsState){
          this.showContinueDownLoadTips = true
          // this.showdownLoadTips = false
          this.packZipTime = Math.ceil(time / 60)
          return  false
        }
        //选中文件的总大小
        if(this.freeDown == 0){
          if(resData.size_total <= resData.visitor_download_max){
            // this.showZip = false
            this.showTooltips = false
            this.handelfiledownload()
          }
          //文件大于免流量下载最大限制 需要弹窗
          if(resData.size_total >= resData.visitor_download_max) {
            // this.showZip = true
            this.showTooltips = true
            this.f1 = true
            this.f2 = false
            this.f3 = false
          }
          //流量不够
          if(this.userInfo.flow_over_total < resData.size_total ){
            // this.showZip = true
            this.showTooltips = true
            this.f1 = false
            this.f2 = false
            this.f3 = true
          }
        }else{
          this.showTooltips = false
          this.handelfiledownload()
        }
      })
    },
    //点击下载按钮
    downloadZip(url){
      var anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = url;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    },
    //移动到 复制到
    handelPopupFile(e){
      // this.prev_id = e.data.id
      console.log('eeeee',e.data)
      this.popup = false
      if(e.title == '复制到'){
        this.handelfilecopy(e.data)
        this.fileList.filter(item=>item.checked = false)

      }
      if(e.title == '移动到'){
        this.handelfilemove(e.data,e)
        this.fileList.filter(item=>item.checked = false)
      }
    },
    //重置云盘数据
    handelPanList(e){
      this.fileList = []
      this.pageIndex = 1
      this.queryState = false
      this.getPanListData({id:e.id,state:true},1,'')
    },
    //复制到
   handelfilecopy(item){
      let params = {
        files_list:[],
      }
     for(let item in this.fileList){
       if(this.fileList[item].checked == true){
         params.files_list.push({
           id:this.fileList[item].id,
           type:this.fileList[item].type
         })
       }
     }
     params.folder_id = item.id == '根目录'?'':item.id
     copyFiles(params).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          this.popup = false
          this.viewType = 1
          this.fileList = []
          this.pageIndex = 1
          this.queryState = false
          this.getPanListData('',1,'')
          this.getUser()
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //移动到
    handelfilemove(item,e){
     let params = {
       files_list:[],
       folder_id:'',
     }
     for(let item in this.fileList){
       if(this.fileList[item].checked == true){
         params.files_list.push({
           id:this.fileList[item].id,
           type:this.fileList[item].type
         })
       }
     }
     params.folder_id = item.id == '根目录'?'':item.id
     moveFiles(params).then(res=>{
       if(res.code == 1){
         this.$Message.success(`${res.msg}`)
         this.popup = false
         this.viewType = 1
         this.fileList = []
         this.pageIndex = 1
         this.queryState = false
        //  this.yunpanTitle = []
        // e.moveNav.forEach((sub) => {
        //   this.yunpanTitle.push({
        //     id:sub.id,
        //     title: sub.name
        //   })
        // })
         this.getPanListData({id:this.prev_id,state:true},1,'')
       }else{
         this.$Message.error(`${res.msg}`)
       }
     })
   },
    //发送文件
    handelsendFile(){
      if(this.openShareStatus == 0) return this.$Message.error('管理员已关闭上传功能，开放日期留意官方公告！')
      let arr = []
      this.fileList.forEach(item=>{
        if(item.checked == true){
          arr.push(item)
        }
      })
      let state = false
      for(let item in arr){
        if(this.$filterKeyword(arr[item].name,this.sensitiveKeywordList)){
          state = true
          break
        }
      }
      if(state){
        this.$Message.error('该文件涉嫌违反国家相关法律法规，禁止上传和分享！')
        return   false
      }
      let data = {
        files_list:arr
      }
      getSizeTotal(data).then(res=>{
        // console.log('TTTT',this.coordinateT)
        this.fileList1 = arr
        this.sendFileAllSize = res.data.size_total
        this.transferShow = true
        this.showPrice1 = true
        this.price = 0
        this.searchPermission = false
        let id  = this.token? this.uploadBefore.expiry_day_list[1] : this.uploadBefore.expiry_day_list[0]
        let val  = this.token? this.uploadBefore.expiry_day_list[1] : this.uploadBefore.expiry_day_list[0]
        this.$set(this.validPeriod,'id',id)
        this.$set(this.validPeriod,'val',val)
        this.supplement = ''

        //头部名称 文件超出2个 显示等
        if(this.fileList1.length > 1){
          this.uploadTitle = this.fileList1.length == 0? '文件传输': (this.fileList1[0].name || this.fileList1[0].files[0].file.name) + '等' + this.fileList1.length + '个文件'
        }else{
          this.uploadTitle = this.uploadTitleVal == "" ?(this.fileList1[0].name || this.fileList1[0].files[0].file.name)  :this.uploadTitleVal
        }
        console.log('token',this.token)
        // this.$router.push({
        //   path:'/home',
        //   name:'Home',
        //   params:{
        //     arr:JSON.stringify(arr),
        //     size_total:res.data.size_total * 1000
        //   }
        // })
      })
    },

    //修改文件头部信息
    reviseUploadTitle(){
      if(this.fileList1.length > 1){
        this.uploadTitleState = true
        this.uploadTitleVal = this.uploadTitle
      }
    },
    //输入框修改头部信息
    handelInput(){
      this.uploadTitle = this.uploadTitleVal
      this.uploadTitleState = false
    },
    //搜索权限
    handelsearchPermission(){
      // 1是游客 0不是游客
      if(this.uploadBefore.visitor_upload == 1){
        this.$Message.error('请登录后使用')
      }else{
        this.searchPermission=!this.searchPermission
      }
    },
    //定价
    handelPrice(){
      // 1是游客 0不是游客
      if(this.uploadBefore.visitor_upload == 1){
        this.$Message.error('请登录后使用')
      }else{
        //判断是不是会员 1是会员 0不是会员
        if(this.userInfo.vip_status == 1){
          // this.showPrice = true
          this.showPrice1 = false
        }else{
          this.showPrice = !this.showPrice
          this.timer = setTimeout(()=>{
            this.showPrice = false
          },4000)
          if(this.showPrice) return   this.$Message.error('该功能只对会员开放')

        }
      }
    },
    //过滤输入框
    limitInput(){
      this.price =
          ("" + this.price) // 第一步：转成字符串
              .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有
    },
    //提供流量
    handelProvideFlow(e){
      // let num = Math.floor((parseInt(this.uploadBefore.member_flow_over) /  this.sendFileAllSize  ))
      // if(num < 0) {
      //   this.$Message.error('流量余额不足，请先充值')
      //   this.flow = '1'
      // } 
      if(this.uploadBefore.visitor_upload == 1){
        this.$Message.error('请登录后使用')
      }else{
        //判断是不是会员 1是会员 0不是会员
        if(this.userInfo.vip_status == 1){
          this.isProvideFlow = true
          const filteredValue = e.target.value.replace(/[^\d]/g, "");
          const parsedValue = parseInt(filteredValue, 10);
          const finalValue = parsedValue >= 1 ? parsedValue : 1;
          this.flow = finalValue;
        }else{
          this.showPrice = true
          this.$Message.error('该功能只对会员开放')
          setTimeout(()=>{
            this.showPrice = false
          },2000)
        }
      }
    },
    //流量--
    reduce(){
      if(this.flow<=1){
        this.isProvideFlow = false
        return false;
      }
      if(this.visitor_upload == 1){
        this.$Message.error('请登录后使用')
      }else{
        if(this.userInfo.vip_status == 1){
          this.isProvideFlow = true
          this.flow--
        }else{
          this.showPrice = true
          this.$Message.error('该功能只对会员开放')
          this.flow = '1'
          setTimeout(()=>{
            this.showPrice = false
          },2000)
        }
      }
    },
    //流量++
    upReduce(){
      let num = Math.floor((parseInt(this.uploadBefore.member_flow_over) /  this.sendFileAllSize  ))
      console.log('用户流量',this.uploadBefore.member_flow_over)
      console.log('文件',this.sendFileAllSize)
      if(this.flow <= num){
        if(this.visitor_upload == 1){
          this.$Message.error('请登录后使用')
        }else{
          if(this.userInfo.vip_status == 1){
            this.isProvideFlow= true
            this.flow++
          }else{
            this.showPrice = true
            this.$Message.error('该功能只对会员开放')
            this.flow = '1'
            setTimeout(()=>{
              this.showPrice = false
            },2000)
          }
        }
      }else{
        this.$Message.error('流量余额不足，请先充值')
      }
    },
    //允许他人操作
    choiceDropdown(id,val){
      this.$set(this.providePermission,'id',id)
      this.$set(this.providePermission,'val',val)
    },
    //有效期
    choiceTimeDropdown(id,val){
      this.$set(this.validPeriod,'id',id)
      this.$set(this.validPeriod,'val',val)
    },
    submitUpload(){
      let uploadFileList = []
      if(!this.isTransferAgree){
        this.$Message.error('请勾选协议')
        return false
      }
      if(this.uploadBefore.member_flow_over <= this.fileAllSize && this.isProvideFlow) {
         this.uploading = false
        this.$store.state.uploading = false
        this.$Message.error('流量余额不足，请先充值')
          return false
        }
        for(let item in this.fileList1){
          uploadFileList.push({
            type:this.fileList1[item].type,
            id:this.fileList1[item].id,
          })
        }
        if(this.uploadTitle.lastIndexOf('等') != -1){
          this.uploadTitle = this.uploadTitle.substring(0,this.uploadTitle.lastIndexOf('等'))
        }
        let data = {
          share_url:'/#/home',
          title:this.uploadTitle,
          prev_id:null,//上层文件夹id，默认为null
          open_search:this.searchPermission?1:0,
          need_pay:this.price ?1:0,
          price:this.price,
          free_num:this.isProvideFlow?this.flow:0,
          remark:this.supplement,
          look_type:this.providePermission.id,
          expiry_day:this.validPeriod.id,
          files_list:uploadFileList
        }
        uploadFileByPan(data).then(res=>{
          if(res.code == 1){
            this.transferShareVisible = true
            this.transferShow = false
            this.link = res.data.share_url
            this.link_code = res.data.pickup_pwd
            this.share_id = res.data.share_id
            this.percentage = 100
            this.speed = '传输完成'
            this.isProvideFlow = false, //是否自定义提供流量
            this.providePermissionVisible = false, //允许他人弹窗
            this.flow = '1',//提供的流量
            this.showPrice1 = false
            this.price = 0
            this.$set(this.providePermission,'id',1)
            this.$set(this.providePermission,'val','下载、转存及预览')
            this.createQRImg(this.share_id)
          }else{
             this.uploading = false
            this.$store.state.uploading = false
            this.$Message.error(`${res.msg}`)
          }

        })
    },
    //跳转协议
    toAgree(data){
      console.log('data',data)
      this.$router.push({
        path:'/advice',
        name:'Advice',
        params:{
          id:data.id
        }
      })
    },
    createQRImg(id){
      let params = {
        share_id:id
      }
      createShareQr(params).then(res=>{
        if(res.code == 0){
          this.$Message.error(`${res.msg}`)
        }else{
          this.share_qr = res.data.qr_img
        }
      })
    },
    downloadImg(){
      let params = {
        share_id:this.share_id
      }
      this.shareQrVisible = false
      downloadQrImage(params).then(res=>{
        if(res.code == 1){
          let posterUrl = res.data.share_img_url64
          this.$fileDownload('',posterUrl,'1')
          this.$Message.success(`${res.msg}`)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    handelEmail(){
      let emails = []
      let emailsStr = ''
      for(let item in this.sendEmail){
        if(this.sendEmail[item].email != ''){
          emails.push(this.sendEmail[item].email)
          emailsStr = JSON.parse(JSON.stringify(emails.join(';')))
        }
      }
      let parmas = {
        share_id:this.share_id,
        emails:emailsStr
      }
      // Loading.service(options);
      this.shareEmailVisible = false
      sendOutEmail(parmas).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          // this.shareEmailVisible = false
          this.sendEmail = [{email:''}]
        }else{
          this.$Message.error(`${res.msg}`)
          // this.shareEmailVisible = true
        }
      })
    },
    handelCopy(){
      if(this.percentage == 100){
        this.$copyText('您有一份文件〔'+this.uploadTitle+'〕待查收，点击链接：'+ this.link + ' 或访问迅鸟快传官网https://xntransfer.com' +  '输入取件码：'+this.link_code+'查看。')
        this.$Message.success('复制成功')

      }else{
        this.$Message.error('链接生成中')
      }
    },
    onCopy(e) {
      this.$Message.success('复制成功')
    },
    onError(e) {
      if(this.copy1 == true){
        this.$Message.error('等链接生成后再使用复制功能')
      }
      if(this.copy2 == true){
        this.$Message.error('等取件码生成后再使用复制功能')
      }
    },
    //再传一次
    againUpload(){
      this.transferShareVisible = false
      this.card = true
      this.fileList1 = []
      this.link = ''
      this.link_code = ''
      this.percentage = 0
      this.uploadSize = 0
      this.isTransferAgree = false
      this.shareQrVisible = false
      this.shareEmailVisible = false
      this.$store.state.uploading = false
      this.uploading = false
    },
    //滚动
    scrollEvent(e,index){
      if (Math.round(e.srcElement.scrollTop + 1 ) + e.srcElement.clientHeight >= e.srcElement.scrollHeight && e.srcElement.scrollHeight != 0) {
          this.pageIndex++
          // this.getshareRecordData()
          if(!this.searchState){
            this.getPanListData({id:this.prev_id,state:true},1)
          }else{
            this.handelsearchAllData(this.searchType)
          }


      }
    },
    //获取弹窗坐标
    setToolCoordinate(e,item,index){
      this.coordinateT = 0
      this.coordinateL = 0
      if(item.checked == false){
        // item.checked = true
        for(let key in this.fileList){
          if(key == index){
            this.fileList[key].checked = true
          }else{
            this.fileList[key].checked = false
          }
        }
      }

      for(let data in this.fileList){
        if(data == index){
          // item.showTool = true
          this.fileList[data].showTool = true
        }else{
          this.fileList[data].showTool = false
        }
      }
      this.$nextTick(()=>{

        var x = e.pageX - e.offsetLeft
        var y = e.pageY - e.offsetTop
        let bodyW  =  this.$refs.panListcoordinate.clientWidth
        let bodyH  =  this.$refs.panListcoordinate.clientHeight
        let popH = this.$refs.pop[index].clientHeight
        let popW = this.$refs.pop[index].clientWidth
        let layerX = e.layerX
        let layerY = e.layerY
        let clientY = e.clientY
        if(layerX > (bodyW - popW) ){
          this.coordinateL = layerX - popW
        }else{
          this.coordinateL = layerX
        }
        if( clientY > bodyH  ){
          this.coordinateT = layerY - popH
        }else{
        this.coordinateT = layerY
        }
      })
    },
    //列表点击三个点
    handelToolPop(e,item,index){
      if(item.checked == false){
        item.checked = true
        for(let key in this.fileList){
          if(key == index){
            this.fileList[key].checked = true
          }else{
            this.fileList[key].checked = false
          }
        }
      }
      item.showTool = !item.showTool
      let bodyW  =  this.$refs.panListcoordinate.clientWidth
      let bodyH  =  this.$refs.panListcoordinate.clientHeight
      let layerX = e.layerX
      let layerY = e.layerY
      let clientY = e.clientY
      if(layerX > (bodyW - 121) ){
        this.coordinateL = layerX - 100
        // this.coordinateT = layerY
      }else{
        this.coordinateL = layerX
      }
        this.coordinateT = layerY
    },
    changeChecked(item){
      item.checked=!item.checked;
      this.fileList.filter(item=>{
        item.showTool = false;
      })
    },
    //大图模式弹窗坐标
    setGridToolCoordinate(e,item,index){
      if(item.checked == false){
        item.checked = true
        for(let key in this.fileList){
          if(key == index){
            this.fileList[key].checked = true
          }else{
            this.fileList[key].checked = false
          }
        }
      }
      let clientX = e.clientX
      let clientY = e.clientY
      if(clientX < 320){
        this.coordinateT = clientY
        this.coordinateL = clientX
      }else if(clientX > 1800){
        this.coordinateT = clientY
        this.coordinateL = clientX
      }else{
        this.coordinateL = clientX
        this.coordinateT = clientY
      }
      for(let data in this.fileList){
        if(data == index){
          item.showTool = true
        }else{
          this.fileList[data].showTool = false
        }
      }
    },
    //大图模式点击三个点
    handelGridToolPop(e,item,index){
      if(item.checked == false){
        item.checked = true
        for(let key in this.fileList){
          if(key == index){
            this.fileList[key].checked = true
          }else{
            this.fileList[key].checked = false
          }
        }
      }
      item.showTool = !item.showTool
      this.coordinateT = e.clientY
      this.coordinateL = e.clientX
    },
    //云盘排序
    panOrderby(type){
      this.queryState = false
      this.pageIndex = 1
      this.fileList = []
      this.fileSortState = !this.fileSortState
      this.fileSortType = type

      if(this.fileSortState ){
        this.fileSort = 2
      }else{
        this.fileSort = 1
      }

      console.log('fileSortState',this.fileSortState)
      console.log('fileSort',this.fileSort)
      if(!this.searchState){
        this.getPanListData({id:this.prev_id,state:true},1,'',type)
      }else{
        this.handelsearchAllData(this.searchType,type)
      }
    },
    //返回
    goback(){
      this.queryState = false
      this.searchState = false
      this.searchText = ''
      // this.searchTypeText = '请选择'
      this.pageIndex = 1
      this.fileList = []
      this.searchType = 0
      this.fileSort = 0
      this.showSearchFileType = false
      this.getPanListData('',1,'')
    },
    //跳转到指定目录
    handelJumpFolder(data){
      console.log('data11223344',data)
      this.fileList = []
      this.yunpanTitle = []
      this.yunpanTitle.push({
        title:'全部文件',
        id:''
      })
      let params = {
        file_id:data.id,
        page:1,
        limit:50,
      }
      this.searchState = false
      jumpFolder(params).then(res=>{
        let pathList = res.data.path_list
        let arr = res.data.list
        for (let item in pathList){
          this.yunpanTitle.push({
            title:pathList[item].name,
            id:pathList[item].id
          })
        }
        this.prev_id = res.data.prev_id
        this.searchType = 0
        for(let item in arr){
          if(arr[item].suffix){
            this.$set(arr[item],'suffix',arr[item].suffix)
          }else{
            this.$set(arr[item],'suffix','folder')
          }
        }
        this.fileList = res.data.list
        console.log('yunpanTitle',this.yunpanTitle)
      })
    },
    //下载
    toShop(){
      this.showTooltips = false
      this.$router.push({
        path:'/shop',
        name:'Shop',
        params:{
          index:3
        }
      })
    },
  },
};
</script>
<style lang='less' scoped>
@import "@assets/css/profile/account/myFiles.less";
</style>